import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import crypto from 'crypto';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes, isTeacherRole, isCourseType, isJobType } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';

import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  ResponsiveImage,
  SectionAdditionallOrdersInfoMaybe,
  IconSpinner,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import { sendEnquiry, sendJobEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import ActionBarMaybe from './ActionBarMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionVideoMaybe from "./SectionVideoMaybe";
import SectionAdditionalInfoMaybe from "./SectionAdditionalInfoMaybe";

import SectionMapMaybe from './SectionMapMaybe';

import css from './ListingPage.module.css';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {getFiles} from "../../util/api";

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MINUTES_TO_ADD = 30;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, new Money(price.amount, price.currency));
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, enquiryJobModalOpenForListingId, params, listingId } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: listingId && enquiryModalOpenForListingId === listingId,
      enquiryJobModalOpen: listingId && enquiryJobModalOpenForListingId === listingId,
      transition: null,
      isOpenModal: false
      // uploadedFiles: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onContactJob = this.onContactJob.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitJobEnquiry = this.onSubmitJobEnquiry.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('fromState', JSON.stringify('fromState'));
    if(typeof window !== 'undefined') {
      const fromStateStorage = localStorage.getItem('fromState');
      const fromListingPage = this.props.location?.state?.fromPage === 'ListingPage';
      if(!fromListingPage && fromStateStorage) {
        localStorage.removeItem('fromState');
      }
    }


    //   const getResult = async () => {
  //     const listingId = new UUID(this.props.params.id);
  //
  //     const files = await getFiles(listingId.uuid);
  //
  //     // setUploadedFiles(files);
  //     this.setState({uploadedFiles: files});
  //   };
  //
  //   getResult();
  }

  async handleSubmit(values, discount, currentListing) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
      onUpdateProfile,
      listingId,
    } = this.props;

    // const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const discountStatus = currentUser?.attributes?.profile?.publicData?.discountStatus;
    const isProviderStripeAccountConnected = currentListing?.attributes?.publicData?.isStripeAccount;


    const { bookingDates, quantity: quantityRaw, customerDiscount, deliveryMethod, ...otherOrderData } = values;
    const providerDiscount = listing?.author?.attributes?.profile?.publicData?.discountStatus;
    const { startDate, endDate } = currentListing?.attributes?.publicData?.dates;
    const bookingDatesMaybe = {
        bookingDates: {
          bookingStart: new Date(startDate),
          bookingEnd: new Date(endDate),
        },
      };

    const token = crypto.randomBytes(8).toString('hex');

    const seats = Number.parseInt(quantityRaw, 10);
    const pricePerSeats = listing?.attributes?.price?.amount/100;

    // 15% commission, so provider gets 85% from payment
    const halfPriceTotalWithCommission = (((pricePerSeats * seats)*85)/100)/2;

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        seats: Number.parseInt(quantityRaw, 10),
        units: 1,
        ...(discountStatus && customerDiscount && customerDiscount[0] === 'true' && { customerDiscount: true }),
        ...(providerDiscount && { providerDiscount }),
        deliveryMethod,
        discount,
        token,
        halfPriceTotalWithCommission,
        isProviderStripeAccountConnected,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    if (!!discount) {
      const transactionTokensMaybe = currentUser?.attributes?.profile?.privateData?.transactionTokens || [];
      const privateData = { privateData: { transactionTokens: [...transactionTokensMaybe, token] } };
      await onUpdateProfile(privateData);
    }

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  setOpenModal = () => {
    this.setState({
      isOpenModal: true
    })
  }

  onContactUser(transition = null) {
    const { currentUser, history, callSetInitialValues, location, listingId } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: listingId });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true, transition });
    }
  }

  onContactJob(transition = null) {
    const { currentUser, history, callSetInitialValues, location, listingId } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryJobModalOpenForListingId: listingId });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryJobModalOpen: true, transition });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, listingId } = this.props;
    const routes = routeConfiguration();
    // const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim(), this.state.transition)
      .then(txId => {
        this.setState({ enquiryModalOpen: false, transition: null });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onSubmitJobEnquiry(values) {
    const { history, params, onSendJobEnquiry, listingId } = this.props;
    const routes = routeConfiguration();
    // const listingId = new UUID(params.id);
    const { message } = values;

    onSendJobEnquiry(listingId, message.trim(), this.state.transition)
      .then(txId => {
        this.setState({ enquiryJobModalOpen: false, transition: null });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('SaleDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      sendJobEnquiryInProgress,
      sendJobEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      areasCertifiedOptions,
      files,
      listingId,
      getListingCustomIdError
    } = this.props;

    const discount = currentUser?.attributes?.profile?.privateData?.reward;
    // const listingId = new UUID(listingId.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const currentUserIsTeacher = isTeacherRole(currentUser);
    const isCourse = isCourseType(currentListing);
    const isJob = isJobType(currentListing);
    const pageName = isJob ? 'ListingJobPage' : 'ListingCoursePage';

    const { variantPrefix = 'listing-card' } = config.listing;
    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const variants = firstImage
      ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
      : [];

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const { descriptionHtml, customPeriods } = publicData;
    const updatedDescription = descriptionHtml ? descriptionHtml : description;

    const listingSlug = rawParams.slug || `${createSlug(title)}-${publicData.customId}`;
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name={pageName} params={params} search={location.search} />;
    }

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer  isOpenSigUpModal={this.state.isOpenModal} setOpenSigUpModal={this.setOpenModal} />;

    if ((showListingError && showListingError.status === 404) || getListingCustomIdError === 'Listing not found') {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (!listingId || showListingError || !currentListing.id) {
      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page
          referrer="strict-origin-when-cross-origin"
          currentUser={currentUser}
          pageName='ListingPage'
          scrollingDisabled={scrollingDisabled}
        >
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.loader}>
                <IconSpinner />
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page
          referrer="strict-origin-when-cross-origin"
          currentUser={currentUser}
          pageName='ListingPage'
          title={errorTitle}
          scrollingDisabled={scrollingDisabled}
        >
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page
          referrer="strict-origin-when-cross-origin"
          currentUser={currentUser}
          pageName='ListingPage'
          title={loadingTitle}
          scrollingDisabled={scrollingDisabled}
        >
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values, discount, currentListing);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const schemaPriceNumber = intl.formatNumber(convertMoneyToNumber(price ? new Money(price.amount, price.currency) : null), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name={pageName}
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', customConfig.filters);
    const categoryOptions = findOptionsForSelectFilter('category', customConfig.filters);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    return (
      <Page
        referrer="strict-origin-when-cross-origin"
        currentUser={currentUser}
        pageName='ListingPage'
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            priceCurrency: price.currency,
            price: schemaPriceNumber,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                {currentListing.id ? (
                  <ActionBarMaybe
                    className={css.actionBarForProductLayout}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                  />
                ) : null}
                {isCourse && <ResponsiveImage
                  rootClassName={css.rootForHeroImage}
                  alt={title}
                  image={firstImage}
                  variants={variants}
                />}
                {isJob &&  <h2 className={css.orderTitle}>{title}</h2>}
                {/*<SectionGallery listing={currentListing} />*/}
                <div className={css.productMobileHeading}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    authorLink={authorLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    isCourse={isCourse}
                  />
                </div>
                <SectionDescriptionMaybe isCourse={isCourse} description={updatedDescription} listingTitle={richTitle} />
                <SectionAdditionallOrdersInfoMaybe
                  onManageDisableScrolling={onManageDisableScrolling}
                  listing={currentListing}
                  isTeacher={currentUserIsTeacher}
                  customPeriods={customPeriods}
                  isCourse={isCourse}
                />

                <SectionVideoMaybe publicData={publicData} customConfig={customConfig} />
                <SectionAdditionalInfoMaybe
                  isCourse={isCourse}
                  uploadedFiles={files}
                  areasCertifiedOptions={areasCertifiedOptions}
                  publicData={publicData}
                  customConfig={customConfig} />

                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
                {<SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError}/>}
                {/*<SectionFiles files={files} filesError={filesError} />*/}
                <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                  onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                  sendEnquiryError={sendEnquiryError}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                  transition={this.state.transition}
                  isJob={isJob}
                />
              </div>
              <div className={css.orderColumnForProductLayout}>
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleOrderSubmit}
                  title={bookingTitle}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={this.onContactUser}
                  onContactJob={this.onContactJob}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  discount={discount}
                  currentUser={currentUser}
                  isCourse={isCourse}
                  isJob={isJob}
                  isJobEnquiryModalOpen={isAuthenticated && this.state.enquiryJobModalOpen}
                  onCloseJobEnquiryModal={() => this.setState({ enquiryJobModalOpen: false })}
                  onSubmitJobEnquiry={this.onSubmitJobEnquiry}
                  transition={this.state.transition}
                  sendJobEnquiryInProgress={sendJobEnquiryInProgress}
                  sendJobEnquiryError={sendJobEnquiryError}
                  showEnquiryModal={true}
                  handleGetStarted={this.setOpenModal}
                  customPeriods={customPeriods}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  enquiryJobModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  sendJobEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
  areasCertifiedOptions: config.custom.areasCertifiedOptions,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  enquiryJobModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  sendJobEnquiryInProgress: bool.isRequired,
  sendJobEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendJobEnquiryInProgress,
    sendJobEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    enquiryJobModalOpenForListingId,
    filesError,
    files,
    id: listingId,
    getListingCustomIdError,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    enquiryJobModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendJobEnquiryInProgress,
    sendJobEnquiryError,
    filesError,
    files,
    listingId,
    getListingCustomIdError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, transition) =>
    dispatch(sendEnquiry(listingId, message, transition)),
  onSendJobEnquiry: (listingId, message, transition) =>
    dispatch(sendJobEnquiry(listingId, message, transition)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: data => dispatch(updateProfile(data))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
