import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionVideoMaybe = props => {
  const { publicData, customConfig } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const videoLink = publicData.video;
  const videoLinkFromUrl = videoLink?.includes('youtu.be') || videoLink?.includes("&") ? videoLink?.substring(videoLink?.indexOf("=") + 1, videoLink?.lastIndexOf("&")) : videoLink?.substring(videoLink?.indexOf("=") + 1);
  const replaceText =  videoLink && (videoLink?.includes('youtu.be') || videoLink?.includes('youtube.com')) ? 'https://youtu.be/' : 'https://vimeo.com/';

  const videoUrl = videoLink && (videoLink?.includes('youtu.be') || videoLink?.includes('youtube.com')) ? 'youtube' : 'vimeo.com';
  let videoLinkId = '';
  if(videoLinkFromUrl !== ''){
    videoLinkId = videoLinkFromUrl
  } else {
    videoLinkId = videoLink.replace(replaceText,'');
  }

  return videoLink ? (
    <div className={css.sectionDetails}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.videoTitle" />
      </h2>
      {videoLink && <div className={css.videoHolder}>
        <div className={css.videoWrapper}>
          {videoUrl === 'youtube' ?
            (<iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoLinkId}`}
                     title="YouTube video player" frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen></iframe>) :

            (<iframe src={`https://player.vimeo.com/video/${videoLinkId}`} width="640"
                     height="275" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                     allowFullScreen></iframe>)
          }
        </div>
      </div>}
    </div>
  ) : null;
};

export default SectionVideoMaybe;
