import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ExternalLink,
  IconFileType,
} from '../../components';
import { associateResult } from '../../util/areasHelpers';

import css from './ListingPage.module.css';
import classNames from "classnames";
import config from "../../config";

const SectionAdditionalInfoMaybe = props => {
  const { publicData, customConfig, uploadedFiles, areasCertifiedOptions, isCourse, customPeriods } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const requiredMaterials = publicData.requiredMaterials;
  const areasCertifiedArray = publicData.areasCertified;
  const createdAreasCertified = areasCertifiedArray ? areasCertifiedArray?.map(objFromA => {
    const temp = areasCertifiedOptions.find(objFromB => (objFromB.key === objFromA) || (objFromB.label === objFromA));
    if(temp) {
      return {key: temp.key, label: temp.label, value: temp.value, id: temp.id, className: css.option,  type: 'areasCertified', parent: temp.parent }
    } else {
      return {key: objFromA.toLowerCase().replace(/ /g,"_"), label: objFromA, value: objFromA, id: 0, className: css.option,  type: 'areasCertified', parent: 'Other' }
    }
  }) : [];


  const associatedAreas = createdAreasCertified && associateResult(createdAreasCertified);
  const areasCertified = <span className={css.subItem}>{
    associatedAreas?.map(item => {
      return Object.entries(item).map(([key, value], i) => {
        return <span className={css.itemParent} key={i}>
          <span className={css.itemParentValue}>{key} </span>
          ({value.map(v => <span className={css.itemSpan}>{v}</span>)})
        </span>})
    })}
  </span>

  const isLicense = publicData?.license;
  const usState = publicData?.state;
  const experience = publicData?.experience;
  const maxSeats = publicData?.maxSeats;
  const minSeats = publicData?.minSeats;
  const education = publicData?.education;
  const isForCredit = publicData?.forCredit;
  const forCredit = publicData?.forCredit === 'isForCredit' ? 'For-credit' : 'Supplemental/Support/Not for-credit';
  const gradeLevelsArray = publicData.gradeLevels;
  const gradeLevels = gradeLevelsArray && gradeLevelsArray.map(item => <span key={item} className={css.itemSpan}>{item}</span>);

  const formattedState = publicData?.state ? config.custom.stateOptions.filter(objFromA => {
    return publicData?.state.find(objFromB => {
      return objFromA.key === objFromB?.toLowerCase().replace(/\s+/g, "_")
    })
  }) : [{}];

  const standardsAlignmentArray = publicData?.standardsAlignment ? config.custom.standardsAlignmentLabelOptions.filter(objFromA => {
    return publicData?.standardsAlignment.find(objFromB => {
      return objFromA.key === objFromB.toLowerCase().replace(/\s+/g, "_")
    })
  }) : [{}];

  const standardsAlignment = standardsAlignmentArray && standardsAlignmentArray.map(item => <span key={item} className={css.itemSpan}>{item.label}</span>)


  return  <div className={css.sectionDetails}>
    <h2 className={css.sectionTitle}>
      <FormattedMessage id="ListingPage.additionalInfoTitle" />
    </h2>
    <ul className={css.list}>
      {areasCertified && <li className={css.item}>Subject Areas: <span className={css.subItem}>{areasCertified}</span></li>}
      {isLicense && !isCourse && <li className={css.item}>Required License: <span className={classNames(css.subItem, css.subItemCap)}>{formattedState[0]?.label}</span></li>}
      {experience && experience[0] !== null && !isCourse && <li className={css.item}>Required experience: <span className={css.subItem}>{experience}</span></li>}
      {minSeats && <li className={css.item}>Minimum Seats: <span className={css.subItem}>{minSeats}</span></li>}
      {maxSeats && <li className={css.item}>Maximum Seats: <span className={css.subItem}>{maxSeats}</span></li>}
      {gradeLevels && gradeLevels.length > 0 && <li className={css.item}>Grade Levels: <span className={css.subItem}>{gradeLevels}</span></li>}
      {/*{standardsAlignment && standardsAlignment.length > 0 && isCourse && <li className={css.item}>Standards Alignment: <span className={css.subItem}>{standardsAlignment}</span></li>}*/}
      {/*{education && <li className={css.item}>Standards Alignment: <span className={css.subItem}>{education}</span></li>}*/}
      {isForCredit && <li className={classNames(css.item, css.itemColumn)}>Could this course be offered for-credit: <span className={css.subItem}>{forCredit}</span></li>}
      {requiredMaterials && <li className={css.item}>{isCourse ? 'Required Materials:' : 'Textbook:'} <span className={css.subItem}>{requiredMaterials}</span></li>}
      {!!uploadedFiles.length && <li className={css.item}>
        Syllabus: <ul className={css.fileUploadList}>
        {uploadedFiles.map((file, index) => {
          return (
            <li key={`file_${index}`} className={css.fileUploadItem}>
              <span className={css.fileUploadName}>
                <ExternalLink key="linkToFacebook" href={file.path} className={css.fileUploadIcon} title={file.path}>
                    <IconFileType fileType={file.type || file.mimeType}/>
                </ExternalLink>
                {file.fileName || file.name}
              </span>
            </li>
          );
        })}
      </ul>
      </li> }
    </ul>
  </div>
};

export default SectionAdditionalInfoMaybe;
